import { Atom, Cmp, Ctrl, Mod } from ":mods";
import { BASE_ROUTES } from "../const";
import { Route, Routes, useNavigate } from "@solidjs/router";
import { actions, hooks } from "../store";
import { NotFoundWithRedirect } from "../components";
import { Dashboard as DashboardLayout, dashboard_actions } from "../../layouts";
import { AdminViewProps } from "../model";
import { createEffect } from "solid-js";

export function Admin() {
  const $: AdminViewProps["$"] = {
    actions: {
      ...actions,
      ...dashboard_actions,
      ...Mod.Account.actions,
    },
    hooks: {
      ...hooks,
    },
  };

  return (
    <Atom.Route.Guarded
      base={BASE_ROUTES.admin}
      layout={() => (
        <DashboardLayout
          topNav={{
            logoHref: BASE_ROUTES.admin,
            base: BASE_ROUTES.admin,
            items: [
              Ctrl.Nav.Element.Filler,
              Mod.I18n.Nav.TopSelector,
              Ctrl.Nav.Element.Divider,
              Mod.Account.Nav.TopNotifications,
              Ctrl.Nav.Element.Divider,
              Mod.Account.Nav.TopProfile,
            ],
          }}
          sideNav={{
            class: "nav-side",
            base: BASE_ROUTES.admin,
            items: [
              [
                "Foundation Course",
                Mod.Courses.CONST.ANCHORES.side.admin.foundation.course_material,
                Mod.Resources.CONST.ANCHORES.side.admin.foundation,
                Mod.Showcase.CONST.ANCHORES.side.admin.foundation,
                ...Mod.Courses.CONST.ANCHORES.side.admin.foundation.submissions,
              ],
            ],
          }}
        />
      )}
      errorElement={() => <NotFoundWithRedirect path={"/"} base={BASE_ROUTES.admin} duration={3000} />}
      guard={{
        title: "museum training admin",
        steps: [Mod.Account.guard.checkUserToken],
      }}
      events={{
        onGuardSuccess: (trigger, { state }) => {
          if (state === "app_init") {
            $.actions.pushToast({
              type: "success",
              message: `welcome back ${$.actions.getTokenDetails()?.first_name}`,
            });
          }
          trigger.loadRoute();
        },
        onGuardFailure: (trigger, { step, stepEquals, error, isBaseRoute, state }) => {
          // console.log("from student failed :: ", state, " :: with step -> ", step);
          if (stepEquals(Mod.Account.guard.checkUserToken)) {
            console.warn("user has no token ", error);
            trigger.navigate({ base: BASE_ROUTES.auth });
          } else {
            console.warn("unhandled route error case in ", error);
          }
        },
        onRouteCleanup({}) {
          $.actions.clearLayer();
        },
      }}
    >
      {() => (
        <>
          <Mod.Account.ProfileRoutes $={$} />
          <Mod.Schedule.AdminRoutes $={$} />
          <Mod.Resources.AdminRoutes $={$} />
          <Mod.Showcase.AdminRoutes $={$} />
          <Mod.Courses.AdminRoutes $={$} />
          <Route path={"/"} element={<div>welcome to the admin panel</div>} />
          <Route path="*" element={<NotFoundWithRedirect path={"/"} base={BASE_ROUTES.admin} duration={3000} />} />
        </>
      )}
    </Atom.Route.Guarded>
  );
}
